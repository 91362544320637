// メディアクエリ内はカスタムプロパティ使用不可のためscss変数を使用
$breakSpMin: 374px;
$breakSp: 767px;
$breakPc: 768px;
$designSp: 375;
$designPc: 1500;


// メディアクエリ
// -----------------------------------
@mixin sp {
	@media (max-width: $breakSp) {
		@content;
	}
}
@mixin spmin {
	@media (max-width: $breakSpMin) {
		@content;
	}
}
@mixin wide {
	@media (min-width: $breakPc) {
		@content;
	}
}

// vw計算
// -----------------------------------
@function sp-vw($target) {
	@return calc(($target / $designSp) * 100vw);
}
@function pc-vw($target) {
	@return calc(($target / $designPc) * 100vw);
}

// リキッドレイアウト対応
// -----------------------------------
// ※ designNumにデザイン上の数値を代入して使用する
@function pcLiquidSize($designNum) {
  $vw: $designNum * 1.25;
  $rem: $designNum + rem;
  $min: $designNum * 0.85 + rem;
	@return clamp(#{$min},#{pc-vw($vw)},#{$rem});
}