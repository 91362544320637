// フェード

.m-scroll-fadein {
  opacity: 0;
  &.is-active {
    transition: opacity 1s;
    opacity: 1;
  }
}
.m-scroll-fadeup {
  opacity: 0;
  transform: translateY(50rem);
  &.is-active {
    transition: opacity 1s, transform 1s;
    transform: translateY(0);
    opacity: 1;
  }
}
.m-scroll-fadedown {
  opacity: 0;
  transform: translateY(-30rem);
  &.is-active {
    transition: opacity 1s, transform 1s;
    transform: translateY(0);
    opacity: 1;
  }
}
.m-scroll-fadeleft {
  opacity: 0;
  transform: translateX(-60rem);
  &.is-active {
    transition: opacity 1s, transform 1s;
    transform: translateX(0);
    opacity: 1;
  }
}
.m-scroll-faderight {
  opacity: 0;
  transform: translateX(60rem);
  &.is-active {
    transition: opacity 1s, transform 1s;
    transform: translateX(0);
    opacity: 1;
  }
}