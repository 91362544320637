// メインのボタン
.m-basic-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border:2px solid var(--colorText1);
  height: 52rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240rem;
  margin:0 auto;
  box-shadow: 2rem 2rem 0px var(--colorText1);
  position: relative;
  background: #FFF;
  @include wide {
    width: 410rem;
    height: 72rem;
    box-shadow: 4rem 4rem 0px var(--colorText1);
    transition: .3s;
    &:hover {
      background: var(--colorText1);
      color: #fff;
      box-shadow:none;
    }
  }
  &--external {
    &:after {
      content: '';
      display: block;
      background: url("/assets/img/common/icon/link.svg") no-repeat center center / contain;
      width: 14rem;
      height: 13rem;
      margin-left: 7rem;
      margin-bottom: 1rem;
      @include wide {
        margin-bottom: -2rem;
      }
    } 
    &:hover {
      &:after {
        @include wide {
          background-image: url("/assets/img/common/icon/link_white.svg");
        }
      }
    }
  }
  &--arrow {
    &:after {
      content: '';
      display: block;
      width: 7rem;
      height: 7rem;
      border: solid var(--colorText1);
      border-width: 2px 2px 0 0;
      transform:translate(0rem,-3.5rem) rotate(45deg);
      position: absolute;
      right: 12rem;
      top: 50%;
      @include wide {
        width: 12rem;
        height: 12rem;
        right: 17rem;
        border-width: 2px 2px 0 0;
        transform:translate(0rem,-6rem) rotate(45deg);
      }
    }
    &:hover {
      &:after {
        @include wide {
          border-color: #FFF;
        }
      }
    }
  }
}
.m-basic-button__text {
  font-size: 14rem;
  letter-spacing: 0.05em;
  font-weight: 600;
  @include wide {
    font-size: 18rem;
  }
}


// サブボタン
.m-sub-button {
  border:1px solid #FFF;
  height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-right: 12rem;
  @include wide {
    height: 72rem;
    border-width: 2px;
    transition: .3s;
    &:hover {
      background: #fff;
      color:var(--colorMain1);
    }
  }
  &:after {
    content: '';
    display: block;
    width: 7rem;
    height: 7rem;
    border: solid #FFF;
    border-width: 1px 1px 0 0;
    transform:translate(0rem,-3.5rem) rotate(45deg);
    position: absolute;
    right: 12rem;
    top: 50%;
    @include wide {
      width: 12rem;
      height: 12rem;
      right: 17rem;
      border-width: 2px 2px 0 0;
      transform:translate(0rem,-6rem) rotate(45deg);
      transition: .3s;
    }
  }
  &:hover:after {
    @include wide {
      border-color:var(--colorMain1);
    }
  }
}
.m-sub-button__text {
  color:#FFF;
  font-size: 13rem;
  font-weight: 600;
  letter-spacing: 0;
  padding-bottom: 2rem;
  @include wide {
    letter-spacing: 0.05em;
    font-size: 18rem;
    transition: .3s;
    a:hover & {
      color:var(--colorMain1);
    }
  }
}


// アクションボタン（フォームなど
.m-action-button {
  background: var(--colorMain1);
  border:2px solid var(--colorMain1);
  color: #fff;
  min-width: 239rem;
  height: 52rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin:0 auto;
  @include wide {
    min-width: 410rem;
    height: 72rem;
    transition: background .3s, border .3s;
    &:hover {
      background: #F79955;
      border-color:#F79955;
    }
  }
  &:after {
    content: '';
    display: block;
    width: 8rem;
    height: 8rem;
    border: solid #FFF;
    border-width: 2px 2px 0 0;
    transform:translate(0rem,-3.5rem) rotate(45deg);
    position: absolute;
    right: 12rem;
    top: 50%;
    @include wide {
      width: 12rem;
      height: 12rem;
      right: 17rem;
      transform:translate(0rem,-6rem) rotate(45deg);
      transition: .3s;
    }
  }
  &--border {
    background: none;
    border:1px solid var(--colorBorder1);
    &:after {
      border-color:var(--colorBorder1);
    }
    @include wide {
      &:hover {
        border-color:var(--colorMain1);
        background: none;
        &:after {
          border-color:var(--colorMain1);
        }
      }
    }
  }
  &--back {
    &:after {
      border-width:  0 0 2px 2px;
      right: auto;
      left:12rem;
      @include wide {
        left:17rem;
      }
    }
  }
  &--disbiled {
    background: var(--colorBorder1);
    border-color:var(--colorBorder1);
    pointer-events: none;
  }
}
.m-action-button__text {
  color:#FFF;
  font-size: 14rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  @include wide {
    font-size: 18rem;
    transition: .3s;
  }
  .m-action-button--border & {
    color:var(--colorText1);
  }
  .m-action-button--border:hover & {
    @include wide {
      color:var(--colorMain1);
    }
  }
}