//記述例
.m-inner { // モジュールは頭に「m-」をつける
  padding-right: var(--sidePaddingSp1);
  padding-left: var(--sidePaddingSp1);
  @include wide {
    max-width: calc(var(--widthDefault) + var(--sidePaddingPc1) * 2);
    margin-right: auto;
    margin-left: auto;
    padding-right: var(--sidePaddingPc1);
    padding-left: var(--sidePaddingPc1);
  }
}


