.m-pagetitle{
  background-color: var(--colorBg2);
  width: 100%;
  height: 344rem;
  position: relative;
  padding:calc(var(--heightHeaderSp) + -25rem) var(--sidePaddingSp1) 0;
  @include wide {
    padding:calc(var(--heightHeaderPc) + 65rem) var(--sidePaddingPc1) 0;
    height: 554rem;
  }
  &:before{
    content: '';
    position: absolute;
    background-color: var(--colorMain1);
    height: 2rem;
    max-width: 292rem;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    @include wide {
      width: calc(100% - var(--sidePaddingPc1) * 2);
      max-width: var(--widthDefault);
    }
  }
}
.m-pagetitle__inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  @include wide {
    max-width: var(--widthDefault);
    margin:0 auto;
  }
}
.m-pagetitle__heading {
  color: var(--colorMain1);
  font-size: 28rem;
  line-height: 1.5;
  letter-spacing: 0.04em;
  text-align: center;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex:1;
  padding-bottom: 60rem;
  @include wide {
    align-items: center;
    font-size: 42rem;
    padding-bottom: 20rem;
  }
}
.m-pagetitle__heading__text {
  position: relative;
  &:before{
    content: '';
    position: absolute;
    background-color: var(--colorMain1);
    height: 4rem;
    width: 55rem;
    top: -46rem;
    right: 0;
    left: 0;
    margin: auto;
  }
}