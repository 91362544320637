.sticky-bar {
  @include wide {
    display: none;
  }
  @include sp {
    position:fixed;
    width: 100%;
    bottom: 20rem;
    left: 0;
    z-index: 100;
  }
}
.sticky-bar__link {
  @include sp {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFEB4B;
    background: #433C3A;
    border: 1px solid var(--colorBorder1);
    height: 56rem;
    font-size: 15rem;
    font-weight: 600;
    letter-spacing: 0.06em;
    border-radius: 12rem;
    margin:0 30rem;
    padding-bottom: 3rem;
    &:after {
      content: '';
      display: block;
      background: url("/assets/img/common/icon/link_yellow.svg") no-repeat center center / contain;
      width: 14rem;
      height: 13rem;
      margin-left: 10rem;
    } 
  }
}