.m-contents-box {
  position: relative;
  width: calc(100% - var(--sidePaddingSp2) * 2);
  margin:0 auto;
  padding: 70rem 27rem 40rem;
  border: solid 2rem var(--colorMain1);
  border-radius: 20rem;
  @include wide {
    width: calc(100% - var(--sidePaddingPc1) * 2);
    max-width: var(--widthDefault);
    padding: 105rem var(--sidePaddingPc1) 140rem;
    border-radius: 40rem;
  }  
}
.m-contents-box__heading {
  position: absolute;
  left: 0;
  right:0;
  top: 0;
  transform: translateY(-50%);
}
.m-contents-box__body {
  @include wide {
    max-width: 1070rem;
    margin:0 auto;
  }
}