
html {
  font-family: var(--fontBase);
  width: 100%;
  font-size: calc(100vw / var(--spViewport));
  @include wide {
    font-size: 1px;
  }
}

body {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 15rem;
  font-weight: 500;
  line-height: 1.8;
  letter-spacing: .13em;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  color: var(--colorText1);
  background: var(--colorBg1);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  position: relative;
  @include sp {
    &.is-navi-active {
      overflow: hidden;
      height: var(--vh);
    }
  }
  @include wide {
    font-size: 16rem;
    min-width: var(--widthMin);
  }
}

::selection {
  background: rgba(#FFA222,0.5);
}

a {
  outline: none;
  color: var(--colorText1);
  text-decoration: none;

  &[data-anchor] {
    cursor: pointer;
  }
}

a:focus,
*:focus {
  outline: none;
}

h1, h2, h3, h4, h5, h6, th {
  font-weight: normal;
}

em {
  font-style: normal;
}

select, input, textarea, button {
  font-family: inherit;
  border-radius: 0;
  -webkit-appearance: none;
  display: block;
}

select, button {
  cursor: pointer;
}

input[type=number] {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input, textarea, select {
  &::-webkit-input-placeholder {
    font-size: 15rem;
    font-weight: 400;
    color: #818181;
    opacity: 1;
    letter-spacing: 0.06em;
    @include wide {
      font-size: 16rem;
    }
  }

  &:-moz-placeholder {
    font-size: 15rem;
    font-weight: 400;
    color: #818181;
    opacity: 1;
    letter-spacing: 0.06em;
    @include wide {
      font-size: 16rem;
    }
  }

  &::-moz-placeholder {
    font-size: 15rem;
    font-weight: 400;
    color: #818181;
    opacity: 1;
    letter-spacing: 0.06em;
    @include wide {
      font-size: 16rem;
    }
  }

  &:-ms-input-placeholder {
    font-size: 15rem;
    font-weight: 400;
    color: #818181;
    opacity: 1;
    letter-spacing: 0.06em;
    @include wide {
      font-size: 16rem;
    }
  }
}

main {
  margin-bottom: auto;
}

ul {
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

.icon_link {
  width: 14rem;
  height: 13rem;
  fill: currentColor;
}