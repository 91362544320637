:root {

  // Design Base Width
  --spDesign: 750;
  --spViewport: 375;

  // break point
  --breakSpMin: #{$breakSpMin};
  --breakSp: #{$breakSp};
  --breakPc: #{$breakPc};

  // Width & Height
  --widthDefault: 1290rem;
  --widthMax: 1500rem;
  --widthMin: 1000rem;
  --heightHeaderSp:100rem;
  --heightHeaderPc:110rem;
  --vh:100vh; // jsで代入するが初期値も入れておく
  --vw:100vw; // jsで代入するが初期値も入れておく

  // Base padding
  --sidePaddingSp1:20rem;
  --sidePaddingSp2:15rem;
  --sidePaddingPc1:36rem;

  // Font
  --fontBase: YakuHanJP_Noto,'Noto Sans JP','Yu Gothic Medium','游ゴシック Medium', YuGothic, '游ゴシック体', 'メイリオ', sans-serif;
  --fontEn1:'Barlow', sans-serif;

  // Color
  --colorGlobal1:#4C4F58;
  --colorMain1:#E3660B;
  --colorMain2:#FFA222;
  --colorMain3:#FFEB4B;
  --colorBg1: #FCFCFC;
  --colorBg2: #F5F5F4;
  --colorBg3: #433C3A;
  //--colorBg3: #F7F8FA;
  --colorText1: #433C3A;
  //--colorText2: #A1A3AD;
  --colorBorder1:#C1BCBC;
  --colorBorder2:#DEDDDD;
  --colorCaution1:#D00030;
  --colorException1:#5C97F7;

 }
