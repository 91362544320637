.l-header {

  position: absolute;
  z-index: 101;
  left: 0;
  top: 0;
  width: 100%;
  margin: 0 auto;
  letter-spacing: 0.04em;
  height: var(--heightHeaderSp);
  @include wide {
    position: fixed;
    min-width: var(--widthMin);
    height: var(--heightHeaderPc);
    padding:0 var(--sidePaddingPc1);
    top: 50rem;
  }

  .m-menu {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 5rem 5rem 10rem rgba(0, 0, 0, 0.15);
  }

  @include sp {
    .head_logo {
      margin:32rem 16rem 0;
      z-index: 101;
      position: relative;
      display: flex;
      justify-content: space-between;
      >a {
        img {
          width: 110rem;
          @include wide {
            width: 200rem;
          }
        }
      }
      .m-menu {
        background-color: #ffffff;
        border: 1rem solid var(--colorMain1);
        border-radius: 17rem;
        width: 84rem;
        height: 34rem;
        font-size: 13rem;
        font-weight: 500;
        font-family: var(--fontEn1);
        color: var(--colorMain1);
        position: fixed;
        right: 16rem;
        top: 32rem;
        z-index: 200;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13rem;
        letter-spacing: 0;
        &.active {
          background: var(--colorMain1);
          color: #fff;
        }
        .menu_icon {
          height: 10rem;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          margin-left: 6rem;
          margin-bottom: 1rem;
        }
        .menu_icon__line {
          width: 15rem;
          height: 2rem;
          background: var(--colorMain1);
          transition: transform .2s;
        }
        &.active {
          .menu_icon {
            justify-content: center;
            margin-left: 3rem;
          }
          .menu_icon__line {
            background: #FFF;
            width: 20rem;
            &:first-child {
              transform: rotate(45deg);
              margin-top: -1rem;
            }
            &:last-child {
              transform: rotate(-45deg);
              margin-top: -2rem;
            }
          }
        }
      }
    }
    .menu_dialog {
      z-index: 100;
      width: 100vw;
      height: 100vh;
      padding-bottom: 100rem;
      background-color: var(--colorBg1);
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(100%);
      transition: transform 0.3s;
      overflow: auto;
      &.active {
        transform: translateX(0);
      }
      .head_logo {
        .logo_l {
          width: 128rem;
        }
        .m-menu {
          filter: none;
          background-color: var(--colorMain1);
          color: white;
        }
      }
      .menu_content {
        font-size: 16rem;
        font-weight: 700;
        width: 292rem;
        margin: 100rem auto 0;
        text-align: center;
        .menu_item {
          width: 100%;
          &:not(:last-child) {
            border-bottom: 1px solid var(--colorBorder1);
          }
          a {
            display: block;
            padding: 12rem 0;
          }
        }
      }
      .menu_info {
        font-size: 16rem;
        font-weight: 700;
        width: 292rem;
        margin: 35rem auto 0;
        text-align: center;
        .tel {
          font-family: var(--fontEn1);
          font-size: 30rem;
        }
        .info {
          font-size: 14rem;
          line-height: 1.5;
          font-weight: 500;
          text-align: left;
          letter-spacing: 0.02em;
          width: fit-content;
          margin: 0 auto;
        }
        .button_application {
          height: 52rem;
          width: 239rem;
          margin: 30rem auto 0;
          font-weight: 700;
          font-size: 14rem;
          letter-spacing: 0.02em;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          background: #fff;
          border: var(--colorBg3) 2px solid;
          box-shadow: 2px 2px 0 var(--colorBg3);
          .icon_link {
            margin-left: 6rem;
            margin-top: 0rem;
          }
        }
      }
    }
  }

  @include wide {
    .m-menu {
      padding: 0 50rem;
      background-color: #ffffff;
      border-radius: 55px;
      max-width: 1390rem;
      width: 100%;
      height: 110rem;
      font-size: 16rem;
      margin-right: auto;
      margin-left: auto;
      @media (max-width: 1160px) {
        padding-right: 30rem;
        padding-left: 30rem;
      }
      .menu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .menu_item {
          font-size: 16rem;
          margin: 0 16rem;
          transition: .3s;
          @media (max-width: 1160px) {
            margin-right: 6rem;
            margin-left: 6rem;
          }
          &:hover {
            color: var(--colorMain1);
          }
        }
        .box {
          margin-left: 34rem;
          @media (max-width: 1160px) {
            margin-left: 15rem;
          }
        }
        .phone {
          font-size: 24rem;
          font-weight: 700;
          font-family: var(--fontEn1);
          @media (max-width: 1160px) {
            font-size: 22rem;
          }
        }
        .button_application {
          height: 39rem;
          width: 206rem;
          font-weight: 700;
          border-radius: 20rem;
          padding-left: 5rem;
          background-color: var(--colorBg3);
          color: var(--colorMain3);
          font-size: 14rem;
          text-align: center;
          padding-top: 6rem;
          position: relative;
          transition: .3s;
          letter-spacing: 0;
          @media (max-width: 1160px) {
            width: 180rem;
            font-size: 12rem;
            padding-top: 8rem;
          }
          &:hover {
            opacity: 0.8;
          }
          .icon_link {
            margin-left: 1rem;
            position: relative;
            top: 1rem;
            width: 13rem;
            height: 12rem;
            @media (max-width: 1160px) {
              width: 12rem;
              height: 11rem;
            }
          }
        }
      }
    }
  }

}
