.m-section-heading {
  text-align: center;
  line-height: 1.5;
}
.m-section-heading__sub {
  font-family: var(--fontEn1);
  display: block;
  color:var(--colorMain1);
  font-size: 16rem;
  font-weight: 700;
  letter-spacing: 0.07em;
  margin-bottom: 7rem;
  @include wide {
    font-size: 20rem;
  }
}
.m-section-heading__main {
  display: block;
  font-weight: 600;
  font-size: 28rem;
  @include wide {
    font-size: 42rem;
  }
}


.m-box-heading {
  background-color: var(--colorMain1);
  color: var(--colorBg1);
  font-size: 22rem;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0.04em;
  width: 100%;
  max-width: 292rem;
  min-height: 48rem;
  border-radius: 999rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:0 auto 0;  
  padding: 6rem 0 8rem;
  @include wide {
    max-width: 850rem;
    min-height: 70rem;
    font-size: 32rem;
    line-height: 1.5;
    padding: 0 0 2rem;
  }
}


.m-sub-box-heading {
  text-align: center;
  background: #FFE1CE;
  font-size: 20rem;
  line-height: 1.5;
  min-height: 50rem;
  font-weight: 600;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:10rem 0;
  @include wide {
    min-height: 60rem;
    font-size: 24rem;
  }
}