.m-basic-table {
  width: 100%;
}
.m-basic-table__body-block ,
.m-basic-table__head-block ,
.m-basic-table__row ,
.m-basic-table__head-area ,
.m-basic-table__field-area {
  text-align: left;
  display: block;
}
.m-basic-table__row {
  @include wide {
    display: flex;
    gap:0 30rem;
    &:not(:last-child) {
      margin-bottom: 6rem;
    }
  }
}
.m-basic-table__head-area {
  background: var(--colorBg2);
  font-weight: 600;
  font-size: 16rem;
  line-height: 1.75;
  letter-spacing: 0;
  padding:5rem 16rem 7rem;
  @include wide {
    font-size: 18rem;
    width: 300rem;
    display: flex;
    align-items: center;
  }
}
.m-basic-table__field-area {
  padding: 16rem 16rem 20rem;
  @include wide {
    position: relative;
    flex:1;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
  }
  &:after {
    @include wide {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 1px solid var(--colorBorder1);
      position: absolute;
      bottom: -2rem;
      right: 0;
      @at-root .m-basic-table__row:last-child & {
        content: none;
      }
    }
  }
}
.m-basic-table__text {
  font-size: 16rem;
  line-height: 1.75;
  letter-spacing: 0;
  font-weight: 400;
  &--bold {
    font-weight: 600;
  }
  &+& {
    @include sp {
      margin-top: 8rem;
    }
  }
}


// 行が多いパターン

.m-basic-table--multi-column  {
  .m-basic-table__body-block {
    @include sp {
      .m-basic-table__row {
        border-bottom: 1px solid var(--colorBorder1);
        padding-bottom: 20rem;
        padding-top: 20rem;
      }
    }
  }
  &:last-child {
    .m-basic-table__body-block {
      @include sp {
        .m-basic-table__row:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }
  .m-basic-table__row {
    @include wide {
      gap:0 16rem;
    }
  }
  .m-basic-table__head-area {
    text-align: center;
    justify-content: center;
  }
  .m-basic-table__field-area {
    @include sp {
      padding-top: 0rem;
      padding-bottom: 0rem;
      &:not(:last-child) {
        padding-bottom: 6rem;
      }
    }
    @include wide {
      padding:6rem 12rem 9rem;
      flex:none;
      &:after {
        width: calc(100% + 16rem);
      }
      &:first-child {
        &:after {
          width: 100%;
        }
      }
      &:not(:first-child) {
        &:before {
          content: '';
          display: block;
          height: 100%;
          border-left: 1px solid var(--colorBorder1);
          position: absolute;
          left: -8rem;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
  .m-basic-table__row:not(:first-child):not(:last-child) {
    .m-basic-table__field-area {
      &:not(:first-child) {
        &:before {
          @include wide {
            height: calc(100% + 12rem);
          }
        }
      }
    }
  }
  .m-basic-table__text {
    font-size: 15rem;
  }
}