.m-document-section {
  background: #FFFFFF;
  padding:90rem var(--sidePaddingSp1);
  margin-bottom: 140rem;
  @include wide {
    max-width: var(--widthDefault);
    width: calc(100% - var(--sidePaddingPc1) * 2);
    margin: 140rem auto 200rem;
    padding:100rem clamp(
      var(--sidePaddingPc1),
      calc((100% - (1070rem + var(--sidePaddingPc1) * 2)) / 2),
      110rem
    ) 130rem;
  }
}
.m-document-section__block {
  &:not(:first-child) {
    margin-top: 25rem;
    padding-top: 25rem;
    border-top: 2px dotted var(--colorBorder1);
  }
  &--no-border {
    &:not(:first-child) {
      border-top: none;
      padding-top: 0;
      @include wide {
        margin-top: 30rem;
      }
    }
  }
}
.m-document-section__large-block {
  &:not(:first-child) {
    margin-top: 40rem;
    @include wide {
      margin-top: 70rem;
    }
  }
}
.m-document-section__sub-block {
  margin-left: 20rem;
  margin-top: 15rem;
  @include wide {
    margin-left: 30rem;
  }
  &--no-indent {
    margin-left: 0;
    margin-top: 20rem;
    @include wide {
      margin-left: 0;
    }
  }
}
.m-document-section__large-heading {
  font-weight: 600;
  font-size: 20rem;
  line-height: 1.75;
  letter-spacing: 0.02em;
  color:var(--colorMain1);
  margin-bottom: 20rem;
  @include wide {
    font-size: 28rem;
    margin-bottom: 30rem;
  }
}
.m-document-section__heading {
  font-weight: 600;
  font-size: 18rem;
  line-height: 1.75;
  letter-spacing: 0.02em;
  color:var(--colorMain1);
  margin-bottom: 5rem;
  @include wide {
    margin-bottom: 10rem;
  }
}
.m-document-section__mid-heading {
  font-weight: 600;
  font-size: 18rem;
  line-height: 1.75;
  letter-spacing: 0.02em;
  margin-bottom: 5rem;
  @include wide {
    margin-bottom: 10rem;
    font-size: 20rem;
  }
  &--separate {
    border-bottom: 1px solid var(--colorBorder1);
    padding-bottom: 12rem;
    margin-bottom: 15rem;
    @include wide {
      margin-bottom: 20rem;
    }
  }
}
.m-document-section__sub-heading {
  font-weight: 600;
  font-size: 16rem;
  line-height: 1.75;
  letter-spacing: 0.02em;
  margin-bottom: 4rem;
}
.m-document-section__lead {
  margin-bottom: 35rem;
  @include wide {
    margin-bottom: 70rem;
  }
}
.m-document-section__text {
  font-size: 15rem;
  line-height: 1.746;
  letter-spacing: 0.02em;
  &+& {
    margin-top: 15rem;
  }
}
.m-document-section__innerlink {
  text-decoration: underline;
}
.m-document-section__bulleted-list {
  margin-top: 5rem;
}
.m-document-section__bulleted-text {
  position: relative;
  padding-left: 17rem;
  &+& {
    margin-top: 10rem;
  }
  &:before {
    content: '';
    display: block;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background: var(--colorText1);
    position: absolute;
    left: 0;
    top: 10rem;
  }
}
.m-document-section__ordered-list {
  counter-reset:number lower-alpha;
  list-style-type: none;
}
.m-document-section__ordered-text {
  position: relative;
  padding-left: 17rem;
  &+& {
    margin-top: 10rem;
  }
  &:before {
    content: '';
    display: block;
    counter-increment: number;
    content: counter(number)'.';
    position: absolute;
    left: 0;
    top: 0;
  }
  &--brackets {
    padding-left: 27rem;
    &:before {
      content: '('counter(number)')';
    }
    &:nth-of-type(n+10) { // 10〜
      padding-left: 35rem;
    }
  }
  &--alphabet {
    padding-left: 20rem;
    &:before {
      counter-increment: lower-alpha;
      content: counter(lower-alpha, lower-alpha) ")";
    }
  }
  .m-document-section__ordered-list {
    margin-top: 12rem;
  }
}