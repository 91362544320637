.l-footer {
  width: 100%;
  background-color: var(--colorBg3);
  color: #ffffff;
  font-size: 16rem;
  position: relative;
  .footer_content {
    .sns_icon img {
      width: 40rem;
      margin: 12rem;
    }
    .credit {
      font-size: 13rem;
      color:var(--colorBorder1);
    }
  }
  .title_text {
    text-align: center;
    color: #FFA222;
    font-weight: 600;
  }
  .flex_change {
    display: flex;
  }
  .phone {
    font-family: var(--fontEn1);
    font-weight: 600;
  }

  @include wide {
    padding-bottom: 50rem;
    .footer_content {
      max-width: 1070rem;
      width: calc(100% - var(--sidePaddingPc1) * 2);
      margin: 0 auto;
    }
    .title_text {
      margin: 71rem auto 0;
      font-size: pcLiquidSize(32);
      width: 100%;
      max-width: 1070rem;
      border-bottom: solid;
      border-color: var(--colorMain2);
    }
    .foot_menu {
      margin: 71rem 110rem 0 0;
      display: flex;
      justify-content: space-between;
      letter-spacing: 0.4rem;
      .flex_change {
        justify-content: space-between;
        flex:1;
        .menu_left {
          margin-top: -25rem;
          .address {
            margin-top: 32rem;
          }
          .phone {
            font-size: 24rem;
          }
          .licence {
            font-size: 13rem;
            margin-top: 20rem;
          }
        }
      }
      .menu-right {
        margin-left: 80rem;
      }
      .flex_row {
        display: flex;
        flex-direction: column;
        .item {
          font-weight: 700;
          color: var(--colorBg1);
          margin-bottom: 30rem;
          text-align: left;
          display: inline-block;
          text-decoration: none;
          transition: color .3s;
          span {
            display: inline-block;
            position: relative;
            &:after {
              display: block;
              position: absolute;
              bottom: -4px;
              left: 0;
              content: '';
              width: 100%;
              height: 2px;
              background: var(--colorMain2);
              transform: scale(0, 1);
              transform-origin: left top;
              transition: transform .3s;
            }
          }
          &:hover {
            color: var(--colorMain2);
            span {
              &:after {
                transform: scale(1, 1);
              }
            }
          }
        }
        .m-button-link:after {
          background-color: var(--colorBg1);
          top: 10rem;
        }
      }
    }
    .sns_icon {
      position: absolute;
      bottom: 120rem;
      right: clamp(
        var(--sidePaddingPc1),
        100%,
        calc((100% - 1070rem) / 2 - 110rem)
      );
      transition: .3s;
      &:hover {
        opacity: 0.7;
      }
    }
    .credit {
      font-weight: 400;
      letter-spacing: 0.4rem;
      position: absolute;
      bottom: 78rem;
      right: clamp(
        var(--sidePaddingPc1),
        100%,
        calc((100% - 1070rem) / 2 - 110rem)
      );
    }
  }

  @include sp {
    text-align: center;
    letter-spacing: 0.4rem;
    padding-top: 55rem;
    padding-bottom: 110rem;
    .footer_content {
      position: relative;
    }
    .title_text {
      width: 292rem;
      font-size: 22rem;
      margin: 0 auto 0;
      line-height: 35rem;
      padding-bottom: 20rem;
      border-bottom: var(--colorMain2) solid 2rem;
    }
    .foot_menu {
      width: 268rem;
      display: flex;
      flex-direction: column;
      margin: 40rem auto 0;
      .flex_change {
        flex-direction: column-reverse;
        .menu_left {
          margin-top: 30rem;
          .foot_logo {
            width: 271rem;
            margin-bottom: 15rem;
            img {
              width: 100%;
            }
          }
          .address {
            line-height: 2;
            margin-bottom: 8rem;
          }
          .phone {
            font-size: 30rem;
            line-height: 1;
            text-align: center;
            margin-bottom: 17rem;
          }
          .licence {
            font-size: 13rem;
          }
        }
        .menu_center {
          line-height: 23rem;
          margin-left: 30rem;
        }
      }
      .flex_row {
        a {
          font-weight: 700;
          display:block;
          color: var(--colorBg1);
          margin-bottom: 30rem;
          text-align: left;
        }
      }
      .menu-right {
        margin-top: 130rem;
        line-height: 10rem;
        a {
          text-align: center;
        }
      }
    }
    .sns_icon {
      position: absolute;
      bottom: 230rem;
      margin: 0 auto;
      width: 100%;
    }
    .credit {
      margin-top: 30rem;
    }
  }
}
  
.l-footer__pagetop {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include sp {
    width: 73rem;
    right: 20rem;
    top: -80rem;
    width: 73rem;
    height: 73rem;
  }
  @include wide {
    width: 93rem;
    height: 93rem;
    right: clamp(
      var(--sidePaddingPc1),
      100%,
      calc((100% - 1070rem) / 2 - 110rem)
    );
    bottom: 220rem;
    cursor: pointer;
  }
  &:before {
    content: '';
    display: block;
    width: 10rem;
    height: 10rem;
    border:1px solid var(--colorBorder1);
    border-width: 1px 0 0 1px;
    transform: rotate(45deg);
    margin-top: -5rem;
    position: relative;
    z-index: 2;
    @include wide {
      width: 12rem;
      height: 12rem;
      margin-top: -7rem;
    }
  }
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border:1px solid var(--colorBorder1);
    border-radius: 50%;
    background: var(--colorBg3);
    @include wide {
      transition: .25s;
    }
  }
  &:hover:after {
    @include wide {
      transform: scale(1.36,1.36);
    }
  }
}
.l-footer__pagetop__text {
  font-size: 13rem;
  letter-spacing: 0;
  font-family: var(--fontEn1);
  color:var(--colorBorder1);
  position: relative;
  z-index: 2;
  @include wide {
    font-size: 16rem;
  }
}