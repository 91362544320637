.m-breadcrumb__list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: -5rem;
}
.m-breadcrumb__item {
  font-size: 13rem;
  letter-spacing: 0.04em;
  position: relative;
  margin-left: 20rem;
  &:not(:last-child) {
    &:after {
      content: '';
      display: block;
      width: 6rem;
      height: 6rem;
      border: solid var(--colorBorder1);
      border-width: 1px 1px 0 0;
      transform: rotate(45deg);
      position: absolute;
      right: -12rem;
      top: calc(50% - 3rem);
    }
  }
}
.m-breadcrumb__text {
  display: inline-block;
  padding: 5rem;
  &[href] {
    color:var(--colorBorder1);
    transition: color .3s;
    @include wide {
      &:hover {
        color:var(--colorMain1);
      }
    }
  }
}